<template>
    <div>

        <el-main style=" padding-top: 5px;padding-bottom: initial  ">
            <el-form label-width="80px" @submit.native.prevent>
                <el-row>
                    <el-col :lg="24">
                        <div :class='[this.$store.getters.getRunTimeTest ? "warning" : "tip"]'>
                            <el-row>
                                <el-col :lg="24" style="line-height: 27px;">
                                    <strong style=" font-size: 16px; ">当前：存货成本核算v5</strong>
                                    <strong class="pull-right" style=" font-size: 16px;color: red ">{{isBuildIng?'正在生成库存成本，稍后刷新页面查看！':''}}</strong>
                                </el-col>
                            </el-row>
                        </div>
                    </el-col>
                    <el-col :lg="24">
                        <el-row>
                            <el-col :sm="12" :md="3">
                                <el-date-picker
                                        v-model="pickerDateMonth"
                                        style=" margin-top: 8px;padding-top: 2px; width: 180px"
                                        type="month"
                                        align="right"
                                        size="mini"
                                        @change="$_pickerDateEvent(),searchChangeEvent()"
                                        :clearable="false"
                                        unlink-panels
                                        range-separator="至"
                                        start-placeholder="开始月份"
                                        end-placeholder="结束月份">
                                </el-date-picker>
                                <el-date-picker
                                        v-show="false"
                                        style=" margin-top: 8px; width: 210px"
                                        v-model="pickerDate"
                                        type="daterange"
                                        :disabled="false"
                                        size="mini"
                                        :picker-options="pickerOptions"
                                        range-separator="至"
                                        start-placeholder="开始日期"
                                        end-placeholder="结束日期"
                                        align="right">
                                </el-date-picker>
                            </el-col>

                            <el-col :sm="12" :md="3">
                                <el-form-item label="仓库:">
                                    <el-select v-model="formData.warehouse_name" no-data-text="没有搜索结果!"
                                               size="mini"
                                               :placeholder="'全部'" default-first-option clearable
                                               @change="formData.warehouse = $event.id,formData.warehouse_name = $event.name,searchChangeEvent()"
                                               @clear="$_searcWarehouseList"
                                               @focus="$_searcWarehouseList" :filter-method="$_warehouseListFilter"
                                               filterable>
                                        <el-option v-for="item in $store.getters.getWarehouseList" :key="item.id" :label="item.name"
                                                   :value="item">
                                            <span style="margin:15px;">{{ item.name}}</span>
                                        </el-option>
                                    </el-select>
                                </el-form-item>
                            </el-col>
                            <el-col :sm="12" :md="12">
                                <el-button style=" margin-top: 8px; " type="primary" round @click="search()"
                                           size="mini">搜索
                                </el-button>
                                <el-button style=" margin-top: 8px; " :disabled="$refs.xGrid == null || $refs.xGrid.getCurrentRecord() == null"
                                           type="primary" round @click="openGoodsInOut($refs.xGrid.getCurrentRecord())"
                                           size="mini">查看产品进仓
                                </el-button>
                                <el-button style=" margin-top: 8px; " type="danger" round @click="showCostAccountingForm = true"
                                           size="mini">生成结存数
                                </el-button>
                                <el-button style=" margin-top: 8px; " type="warning" round @click="searchMustDoDayBanlance(),showModel.isShow = true"
                                           size="mini">快速生成
                                </el-button>
                                <!--<el-button type="primary" size="mini" @click="$router.back()"
                                           style="margin-bottom: 2px;margin-top: 2px">返回
                                </el-button>-->
                                <el-checkbox style="padding-left: 7px" v-model="formData.showAverageWeight" @change="search()">以进仓重量进行核算(结存重量与期初重量会存在差异)</el-checkbox>

                            </el-col>
                            <el-dialog width="420px"  title="生成库存成本" :visible.sync="showCostAccountingForm" :close-on-click-modal="false">
                                <el-form :model="costAccountingForm"  label-width="100px" >
                                    <el-row>
                                        <el-col :lg="24">
                                            <el-form-item label="规格" prop="specification" label-width="40px">
                                                <el-select v-model="costAccountingForm.specification" size="mini" no-data-text="没有搜索结果!" placeholder="全部"
                                                           default-first-option remote
                                                           style="width: 260px"
                                                           :remote-method="(query) => $_searchWarehouseStock(query,'',true)" clearable filterable >
                                                    <el-option v-for="item in this.$store.getters.getSpecificationList" :key="item.id" :label="item.specification_name" :value="item.specification_name">
                                                        <span style="margin:15px;">{{ item.specification_name}}</span>
                                                    </el-option>
                                                </el-select>
                                            </el-form-item>
                                        </el-col>
                                        <el-col :lg="24">
                                            <vxe-input size="mini" v-model="costAccountingForm.beginMonth" type="month" valueFormat="yyyy-MM-dd" class="my-domain">
                                                <template #prefix>
                                                    <span>从</span>
                                                </template>
                                                <template #suffix>
                                                    <span>月开始生成</span>
                                                </template>
                                            </vxe-input>
                                        </el-col>

                                    </el-row>
                                </el-form>
                                <div slot="footer" class="dialog-footer">
                                    <el-button @click="showCostAccountingForm = false">取 消</el-button>
                                    <el-button type="primary" @click="buildBanlance()">确 定</el-button>
                                </div>
                            </el-dialog>
                            <el-col :lg="5" :sm="12" :md="8">
                                <vxe-toolbar class="pull-left" style=" height: 42px; " custom export
                                             ref="xToolbar2">
                                </vxe-toolbar>
                            </el-col>
                        </el-row>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :lg="24">
                        <vxe-grid
                                border
                                resizable
                                show-overflow
                                keep-source
                                ref="xGrid"
                                id="toolbar_xGrid"
                                align="right"
                                size="mini"
                                :height="(this.getViewHeight() - 130)+''"
                                :cell-class-name="cellClassName"
                                highlight-hover-row
                                highlight-current-row
                                show-footer
                                :row-class-name="tableRowClassName"
                                :print-config="{}"
                                :export-config="{type: ['xlsx'] ,types: ['xlsx', 'csv', 'html', 'xml', 'txt'],isFooter:true,isColgroup:true}"
                                :data="tableDataMain"
                                :columns="tableColumn"
                                :custom-config="{storage:true}"
                                @keydown="$_onGridKeydown"
                                :footer-method="footerMethod"
                                :mouse-config="{selected: true}"
                                :edit-config="{showIcon:false,trigger: 'dblclick', mode: 'cell', showStatus: false}"
                                :keyboard-config="{enterToTab:false,isArrow: true, isDel: false, isEnter: false, isTab: true,isEdit:true}">

                            <template #pager>
                                <vxe-pager
                                        :layouts="['Sizes', 'PrevJump', 'PrevPage', 'Number', 'NextPage', 'NextJump', 'FullJump', 'Total']"
                                        :page-sizes="[50,100,500,1000,2500,5000]"
                                        :current-page.sync="tablePage.currentPage"
                                        :page-size.sync="tablePage.pageSize"
                                        :total="tablePage.total"
                                        @page-change="handlePageChange">
                                </vxe-pager>
                            </template>
                        </vxe-grid>
                    </el-col>

                    <vxe-modal v-model="showModel.isShow" :lock-view="false" :mask="false" show-zoom resize
                               margin-size="-500" width="700" title="最近发生变动的规格(需要重新生成存货成本)">
                        <template #default>
                            <el-row>
                                <el-col :sm="12" :md="8">
                                    <el-button type="primary" @click="searchMustDoDayBanlance" round
                                               size="mini">查询
                                    </el-button>
                                    <el-button type="warning" @click="buildMustDoDayBanlance" round
                                               size="mini">确定生成
                                    </el-button>
                                </el-col>
                            </el-row>
                            <vxe-grid
                                    border
                                    id="modalGrid"
                                    ref="modalGrid"
                                    show-overflow
                                    resizable
                                    height="450"
                                    size="mini"
                                    highlight-current-row
                                    :data="tableDataModel"
                                    :mouse-config="{selected: true}"
                                    :pager-config="tableDataModelPage"
                                    :columns="tableColumnModel">
                            </vxe-grid>
                        </template>
                    </vxe-modal>
                </el-row>

            </el-form>
        </el-main>
    </div>
</template>

<script>

    import {filterRender} from "@/utils/gird-filter";

    import XEUtils from "xe-utils";

    export default {

        data() {
            var now = new Date();
            var year = now.getFullYear();
            var month = now.getMonth() + 1;
            if (month < 10) {
                month = '0' + month;
           }
            // 本月第一天
            var startDate = new Date(year + '-' + month + '-01 00:00:00');
            return {
                showModel: {
                    isShow: false,
                },
                isBuildIng:false,
                showCostAccountingForm:false,
                costAccountingForm : {
                    beginMonth:'',
                    specification:'',
                },
                type:'',
                formData:{
                    warehouse:'',
                    warehouse_name:'',
                    showAverageWeight:true,
                },
                warehouseList: [],
                pickerDate: [startDate,new Date(year + '-' + month + '-' + new Date(year, month, 0).getDate() + ' 23:59:00')],
                pickerMonthRange: [startDate, new Date()],
                pickerDateMonth: new Date(),
                tablePage: {
                    total: 0,
                    currentPage: 1,
                    pageSize: 500,
               },
                pickerOptions: {
                    shortcuts: [{
                        text: '最近一周',
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
                            picker.$emit('pick', [start, end]);
                       }
                   }, {
                        text: '最近一个月',
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
                            picker.$emit('pick', [start, end]);
                       }
                   }, {
                        text: '最近三个月',
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
                            picker.$emit('pick', [start, end]);
                       }
                   }, {
                        text: '最近一年',
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(start.getTime() - 3600 * 1000 * 24 * 365);
                            picker.$emit('pick', [start, end]);
                       }
                   }, {
                        text: '本月',
                        onClick(picker) {
                            var now = new Date();
                            var year = now.getFullYear();
                            var month = now.getMonth() + 1;
                            if (month < 10) {
                                month = '0' + month;
                           }
                            // 本月第一天
                            var startDate = new Date(year + '-' + month + '-01 00:00:00');
                            picker.$emit('pick', [startDate, new Date(year + '-' + month + '-' + new Date(year, month, 0).getDate() + ' 23:59:00')]);
                       }
                   }, {
                        text: '上个月',
                        onClick(picker) {
                            var now = new Date();
                            var year = now.getFullYear();
                            var month = now.getMonth();
                            if (month == 0) {
                                month = 12;
                                year = year - 1;
                           }
                            if (month < 10) {
                                month = '0' + month;
                           }
                            var myDate = new Date(year, month, 0);
                            // 上个月第一天
                            var startDate = new Date(year + '-' + month + '-01 00:00:00');
                            // 上个月最后一天
                            var endDate = new Date(year + '-' + month + '-' + myDate.getDate() + ' 23:59:00');
                            picker.$emit('pick', [startDate, endDate]);
                       }
                   }]
               },

                tableColumn: [
                    {type: 'seq',fixed: 'left', title: '序号', width: 40},
                    {
                        field:'specification',fixed: 'left', width: 160, title: '规格', slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/costAccounting/searchHeard', this.pickerDate)
                       }
                   },{
                        field:'category',fixed: 'left', width: 80, title: '类别', slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/costAccounting/searchHeard', this.pickerDate)
                       }
                   }, {
                        field:'before_count', width: 80, title: '期初支数', slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/costAccounting/searchHeard', this.pickerDate)
                       }
                   },  {
                        field:'before_stock_amount', width: 100, title: '期初金额', formatter: ({cellValue}) => {
                            return cellValue
                       }, slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/costAccounting/searchHeard', this.pickerDate)
                       }
                   }, {
                        field:'before_weighing_heavy_total', width: 100, title: '期初重量', formatter: ({cellValue}) => {
                            return cellValue
                       }, slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/costAccounting/searchHeard', this.pickerDate)
                       }
                   }, {
                        field:'before_weighing_heavy_total_org', width: 100, title: '期初重量2', formatter: ({cellValue}) => {
                            return cellValue
                        }, slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/costAccounting/searchHeard', this.pickerDate)
                        }
                    },{
                        title: '本期合计',
                        children: [
                            {
                                field:'now_single_count_total_jc', width: 80, title: '进仓支数', slots: {
                                    header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/costAccounting/searchHeard', this.pickerDate)
                               }
                           },  {
                                field:'now_naked_price_total_price_jc', width: 100, title: '进仓金额', formatter: ({cellValue}) => {
                                    return cellValue
                               }, slots: {
                                    header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/costAccounting/searchHeard', this.pickerDate)
                               }
                           },  {
                                field:'now_weighing_heavy_total_jc', width: 100, title: '进仓重量', formatter: ({cellValue}) => {
                                    return cellValue
                               }, slots: {
                                    header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/costAccounting/searchHeard', this.pickerDate)
                               }
                           }, {
                                field:'now_single_count_total_cc', width: 80, title: '出仓支数', slots: {
                                    header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/costAccounting/searchHeard', this.pickerDate)
                               }
                           }, {

                                field:'now_naked_price_total_cc', width: 100, title: '出仓金额', formatter: ({cellValue}) => {
                                    return cellValue
                               }, slots: {
                                    header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/costAccounting/searchHeard', this.pickerDate)
                               }
                           }, {
                                field:'now_weighing_heavy_total_cc', width: 100, title: '出仓重量', formatter: ({cellValue}) => {
                                    return cellValue
                               }, slots: {
                                    header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/costAccounting/searchHeard', this.pickerDate)
                               }
                           }, {
                                field:'now_weighing_heavy_total_cc_org', width: 100, title: '出仓重量2', formatter: ({cellValue}) => {
                                    return cellValue
                                }, slots: {
                                    header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/costAccounting/searchHeard', this.pickerDate)
                                }
                            },
                        ]
                   },
                    {title: '本期结存',
                    children: [
                            {
                                field:'balance_single_count', width: 80, title: '结存支数', slots: {
                                    header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/costAccounting/searchHeard', this.pickerDate)
                                }
                            },{
                                field:'balance_naked_price', width: 100, title: '结存金额', formatter: ({cellValue}) => {
                                    return cellValue
                                }, slots: {
                                    header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/costAccounting/searchHeard', this.pickerDate)
                                }
                            },{
                                field:'balance_weighing_heavy_total', width: 100, title: '结存重量', formatter: ({cellValue}) => {
                                    return cellValue
                                }, slots: {
                                    header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/costAccounting/searchHeard', this.pickerDate)
                                }
                            },{
                                field:'balance_weighing_heavy_total_org', width: 100, title: '结存重量2', formatter: ({cellValue}) => {
                                    return cellValue
                                }, slots: {
                                    header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/costAccounting/searchHeard', this.pickerDate)
                                }
                            },{
                                field:'balance_single_price', width: 100, title: '结存支价', formatter: ({cellValue}) => {
                                    return cellValue
                                }, slots: {
                                    header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/costAccounting/searchHeard', this.pickerDate)
                                }
                            },
                        ]
                    },
                    {
                        title: '本期进出明细',
                        children: [
                            {
                                title: '进仓明细',
                                children: [
                                    {
                                        field:'now_single_count_jc_wg', width: 105, title: '外购进仓支数', slots: {
                                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/costAccounting/searchHeard', this.pickerDate)
                                        }
                                    },  {
                                        field:'now_naked_price_jc_wg', width: 105, title: '外购进仓金额', formatter: ({cellValue}) => {
                                            return cellValue
                                        }, slots: {
                                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/costAccounting/searchHeard', this.pickerDate)
                                        }
                                    },{
                                        field:'now_weighing_heavy_jc_wg', width: 105, title: '外购进仓重量', formatter: ({cellValue}) => {
                                            return cellValue
                                        }, slots: {
                                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/costAccounting/searchHeard', this.pickerDate)
                                        }
                                    }, {
                                        field:'now_single_count_jc_jg', width: 105, title: '加工进仓支数', slots: {
                                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/costAccounting/searchHeard', this.pickerDate)
                                        }
                                    },  {
                                        field:'now_naked_price_jc_jg', width: 105, title: '加工进仓金额', formatter: ({cellValue}) => {
                                            return cellValue
                                        }, slots: {
                                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/costAccounting/searchHeard', this.pickerDate)
                                        }
                                    },{
                                        field:'now_weighing_heavy_jc_jg', width: 105, title: '加工进仓重量', formatter: ({cellValue}) => {
                                            return cellValue
                                        }, slots: {
                                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/costAccounting/searchHeard', this.pickerDate)
                                        }
                                    },
                                ]
                            },
                            {
                                title: '出仓明细',
                                children: [
                                    {
                                        field:'now_single_count_cc_kd', width: 110, title: '销售出仓支数', slots: {
                                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/costAccounting/searchHeard', this.pickerDate)
                                        }
                                    }, {
                                        field:'now_kd_cost_cc_kd', width: 110, title: '销售出仓金额', formatter: ({cellValue}) => {
                                            return cellValue
                                        }, slots: {
                                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/costAccounting/searchHeard', this.pickerDate)
                                        }
                                    }, {
                                        field:'now_weighing_heavy_cc_kd', width: 105, title: '销售出仓重量', formatter: ({cellValue}) => {
                                            return cellValue
                                        }, slots: {
                                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/costAccounting/searchHeard', this.pickerDate)
                                        }
                                    },
                                    {
                                        field:'now_single_count_cc_jg', width: 105, title: '加工出仓支数', slots: {
                                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/costAccounting/searchHeard', this.pickerDate)
                                        }
                                    }, {
                                        field:'now_naked_price_cc_jg', width: 105, title: '加工出仓金额', formatter: ({cellValue}) => {
                                            return cellValue
                                        }, slots: {
                                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/costAccounting/searchHeard', this.pickerDate)
                                        }
                                    }, {
                                        field:'now_weighing_heavy_cc_jg', width: 105, title: '加工出仓重量', formatter: ({cellValue}) => {
                                            return cellValue
                                        }, slots: {
                                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/costAccounting/searchHeard', this.pickerDate)
                                        }
                                    },
                                ]
                            },
                            {
                                title: '盘点明细',
                                children: [
                                    {
                                        field:'now_single_count_jc_py', width: 105, title: '盘点支数', slots: {
                                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/costAccounting/searchHeard', this.pickerDate)
                                        }
                                    },  {
                                        field:'now_naked_price_jc_py', width: 105, title: '盘点金额', formatter: ({cellValue}) => {
                                            return cellValue
                                        }, slots: {
                                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/costAccounting/searchHeard', this.pickerDate)
                                        }
                                    },{
                                        field:'now_weighing_heavy_jc_py', width: 105, title: '盘点重量', formatter: ({cellValue}) => {
                                            return cellValue
                                        }, slots: {
                                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/costAccounting/searchHeard', this.pickerDate)
                                        }
                                    },
                                ]
                            },
                            {
                                title: '每支成本明细',
                                children: [
                                    {
                                        field: 'finished_cost', width: 140, title: '每支加工出仓成本',
                                        titleHelp: {message: '加工出仓成本= (期初金额+本期外购进仓金额+\'否\'合计单据上出仓金额)/(期初支数+本期外购进仓支数+\'否\'合计单据上出仓金额)\n否：加工单据上自动核算为否的金额'},
                                        formatter: ({cellValue}) => {
                                            return cellValue
                                        }, slots: {
                                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/costAccounting/searchHeard', this.pickerDate)
                                        }
                                    }, {
                                        field: 'kd_cost', width: 115, title: '每支销售成本',
                                        titleHelp: {message: '销售成本= (期初金额+本期外购进仓金额+本期加工进仓金额-本期加工出仓金额+本期盘点进仓金额)/(期初支数+本期外购进仓支数+本期加工进仓支数-本期加工出仓支数+本期盘点进仓支数)'},
                                        formatter: ({cellValue}) => {
                                            return cellValue
                                        }, slots: {
                                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/costAccounting/searchHeard', this.pickerDate)
                                        }
                                    }, {
                                        field: 'inventory_cost',
                                        width: 105,
                                        title: '每支盘点成本',
                                        titleHelp: {message: '销售成本= (期初金额+本期外购进仓金额+本期加工进仓金额+本期盘点进仓金额)/(期初支数+本期外购进仓支数+本期加工进仓支数+本期盘点进仓支数)'},
                                        formatter: ({cellValue}) => {
                                            return cellValue
                                        },
                                        slots: {
                                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/costAccounting/searchHeard', this.pickerDate)
                                        }
                                    }
                                ]
                            }
                        ]
                    },
                ],
                tableDataMain: [],
                filterData: {},
                sortData: {
                    'category':'asc',
                    'specification':'asc'
                },
                filterDataModel:{},
                sortDataModel:{},
                tableDataModel: [],
                tableDataModelPage: {
                    total: 0,
                    currentPage: 1,
                    pageSize: 500,
                    pageSizes: [500, 1000, 2000, 5000]
                },
                tableColumnModel: [
                    {
                        field:'specification_name', fixed: 'left', title: '规格', width: 130,
                        slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterDataModel, this.sortDataModel, '/admin/costAccounting/searchHeardMustDoDayBanlance')
                        }
                    },
                    {
                        field:'category_name', fixed: 'left', width: 85, title: '类别',
                        slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterDataModel, this.sortDataModel, '/admin/costAccounting/searchHeardMustDoDayBanlance')
                        }
                    },
                    {
                        field:'last_only_bill_id', fixed: 'left', width: 185, title: '最后更新单号',
                        slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterDataModel, this.sortDataModel, '/admin/costAccounting/searchHeardMustDoDayBanlance')
                        }
                    },
                    {
                        field:'bill_change_time', fixed: 'left', width: 185, title: '将生成存货成本核算日期',
                        formatter: ({cellValue}) => {return this.formatDate(cellValue,'yyyy-MM')},
                        slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterDataModel, this.sortDataModel, '/admin/costAccounting/searchHeardMustDoDayBanlance')
                        }
                    },
                ]
           }
       },
        methods: {
            check_count_stockEvent(row){
                row.check_count_stock = this.$XEUtils.subtract(row.check_count,row.warehouse_count_orig);
                if (row.check_count_stock != 0){
                    row.single_price = row.average_price
               }else{
                    row.single_price = 0
               }
                row.amount_of_product = this.$XEUtils.multiply(row.single_price,row.check_count_stock)
           },
            singlePriceEvent(row){
                row.amount_of_product = this.$XEUtils.multiply(row.single_price,row.check_count_stock)
           },
            searchChangeEvent(){
                if(!this.isBlankVue(this.formData.warehouse)){
                    this.search();
               }
           },
            search() {
                    this.changeTableColumn();
                    this.tableDataMain = [];
                    const loading = this.$loading({
                        lock: true,
                        text: '饮茶先啦，数据查询中，请耐心等待!',
                        spinner: 'el-icon-loading',
                        background: 'rgba(0, 0, 0, 0.7)'
                   });
                    this.$axios({
                        method: 'POST',
                        url: '/admin/costAccounting/search',
                        data: {
                            type:this.type,
                            currentPage: this.tablePage.currentPage,
                            pageSize: this.tablePage.pageSize,
                            sortData: this.sortData,
                            filterData: this.filterData,
                            pickerDate: this.pickerDate,
                            formData:this.formData
                       }
                   }).then((response) => {          //这里使用了ES6的语法
                        loading.close();
                        //console.log(response)       //请求成功返回的数据
                        if (response.data.state == 'fail'){
                            this.$message({
                                showClose: true,
                                message: response.data.msg,
                                type: 'error'
                           });
                            return
                       }else if (response.status === 200) {
                            this.isBuildIng = response.data.isBuildIng
                            this.tableDataMain = response.data.page.list
                            this.tablePage.pageSize = response.data.page.pageSize
                            this.tablePage.total = response.data.page.totalRow
                            this.tablePage.currentPage = response.data.page.pageNumber
                       }
                   }).catch((error) => {
                        loading.close();
                        console.log(error)
                   });
                    if(this.formData.showAverageWeight){
                        this.$refs.xGrid.showColumn(this.$refs.xGrid.getColumnByField('before_weighing_heavy_total'))
                        this.$refs.xGrid.showColumn(this.$refs.xGrid.getColumnByField('now_weighing_heavy_total_cc'))
                        this.$refs.xGrid.showColumn(this.$refs.xGrid.getColumnByField('balance_weighing_heavy_total'))
                        this.$refs.xGrid.hideColumn(this.$refs.xGrid.getColumnByField('before_weighing_heavy_total_org'))
                        this.$refs.xGrid.hideColumn(this.$refs.xGrid.getColumnByField('now_weighing_heavy_total_cc_org'))
                        this.$refs.xGrid.hideColumn(this.$refs.xGrid.getColumnByField('balance_weighing_heavy_total_org'))
                    }else{
                        this.$refs.xGrid.hideColumn(this.$refs.xGrid.getColumnByField('before_weighing_heavy_total'))
                        this.$refs.xGrid.hideColumn(this.$refs.xGrid.getColumnByField('now_weighing_heavy_total_cc'))
                        this.$refs.xGrid.hideColumn(this.$refs.xGrid.getColumnByField('balance_weighing_heavy_total'))
                        this.$refs.xGrid.showColumn(this.$refs.xGrid.getColumnByField('before_weighing_heavy_total_org'))
                        this.$refs.xGrid.showColumn(this.$refs.xGrid.getColumnByField('now_weighing_heavy_total_cc_org'))
                        this.$refs.xGrid.showColumn(this.$refs.xGrid.getColumnByField('balance_weighing_heavy_total_org'))
                    }

           },
            buildMustDoDayBanlance() {
                this.isBuildIng = true;
                this.showCostAccountingForm = false;
                this.$axios({
                    method: 'POST',
                    url: '/admin/costAccounting/buildMustDoDayBanlance',
                }).then((response) => {          //这里使用了ES6的语法
                    //console.log(response)       //请求成功返回的数据
                    if (response.data.state == 'fail'){
                        this.$message({
                            showClose: true,
                            message: response.data.msg,
                            type: 'error'
                        });
                    }else if (response.status === 200) {
                        this.$message({
                            showClose: true,
                            message: '更新成功',
                            type: 'success'
                        });
                    }
                    this.isBuildIng = response.data.isBuildIng
                }).catch((error) => {
                    console.log(error)
                });
            },
            handlePageChange({currentPage, pageSize}) {
                this.tablePage.currentPage = currentPage
                this.tablePage.pageSize = pageSize
                this.search();
           },
            tableRowClassName({rowIndex}) {
                if (rowIndex % 2 === 1) {
                    return 'success-row';
               } else {
                    return '';
               }
           },
            cellClassName({column}) {
                if (column.property === 'check_count' || column.property ===  'single_price') {
                    return 'col-fail'
               }
           },
            sumNum(list, field) {
                let count = Number(0)
                list.forEach(item => {
                    count = this.$XEUtils.add(count, item[field]);
               })
                return count;
           },
            footerMethod({columns, data}) {
                const sums = []
                columns.forEach((column, columnIndex) => {
                    if (columnIndex === 0) {
                        sums.push('合计')
                   } else {
                        if (
                            column.property === 'before_count' || column.property === 'before_stock_amount' || column.property === 'before_weighing_heavy_total'
                            || column.property ===  'before_weighing_heavy_total_orig'
                            || column.property === 'now_single_count_total_jc' || column.property === 'now_naked_price_total_price_jc' || column.property === 'now_weighing_heavy_total_jc'
                            || column.property === 'now_single_count_total_cc' || column.property === 'now_naked_price_total_cc' || column.property === 'now_weighing_heavy_total_cc'
                            || column.property === 'now_weighing_heavy_total_cc_org'
                            || column.property === 'balance_single_count' || column.property === 'balance_naked_price' || column.property === 'balance_weighing_heavy_total'
                            || column.property === 'balance_weighing_heavy_total_org'
                            || column.property === 'now_single_count_jc_wg' || column.property === 'now_naked_price_jc_wg' || column.property === 'now_weighing_heavy_jc_wg'
                            || column.property === 'now_single_count_jc_jg' || column.property === 'now_naked_price_jc_jg' || column.property === 'now_weighing_heavy_jc_jg'
                            || column.property === 'now_single_count_cc_kd' || column.property === 'now_kd_cost_cc_kd' || column.property === 'now_weighing_heavy_cc_kd'
                            || column.property === 'now_single_count_cc_jg' || column.property === 'now_naked_price_cc_jg' || column.property === 'now_weighing_heavy_cc_jg'
                            || column.property === 'now_single_count_jc_py' || column.property === 'now_naked_price_jc_py' || column.property === 'now_weighing_heavy_jc_py'
                        ) {
                            sums.push(this.sumNum(data, column.property))
                       } else {
                            sums.push('')
                       }
                   }
               })
                //this.autoComputed();
                // 返回一个二维数组的表尾合计
                return [sums]
           },
           changeTableColumn(){
           },
            //生成库存成本
            buildBanlance(){
                this.isBuildIng = true;
                this.showCostAccountingForm = false;
                this.$axios({
                    method: 'POST',
                    url: '/admin/costAccounting/buildBanlance',
                    data: {
                        beginMonth:this.costAccountingForm.beginMonth,
                        specification: this.costAccountingForm.specification,
                    }
                }).then((response) => {          //这里使用了ES6的语法
                    //console.log(response)       //请求成功返回的数据
                    if (response.data.state == 'fail'){
                        this.$message({
                            showClose: true,
                            message: response.data.msg,
                            type: 'error'
                        });
                    }else if (response.status === 200) {
                        this.$message({
                            showClose: true,
                            message: '更新成功',
                            type: 'success'
                        });
                    }
                    this.isBuildIng = response.data.isBuildIng
                }).catch((error) => {
                    console.log(error)
                });
            },
            //打开产品进仓
            openGoodsInOut(row){
                const{href} = this.$router.resolve({path: '/goodsInOut', query: {warehouse_name:this.formData.warehouse_name,warehouse: this.formData.warehouse,category: row.category,specification:row.specification,pickerDate:[XEUtils.toDateString(this.pickerDate[0], 'yyyy-MM-dd'),XEUtils.toDateString(this.pickerDate[1], 'yyyy-MM-dd')]}});
                window.open(href,'_blank');
            },
            searchMustDoDayBanlance(){
                this.$axios({
                    method: 'post',
                    url: '/admin/costAccounting/searchMustDoDayBanlance',
                    data: {
                        currentPage: this.tableDataModelPage.currentPage,
                        pageSize: this.tableDataModelPage.pageSize,
                        sortData: this.sortDataModel,
                        filterData: this.filterDataModel,
                    }
                }).then((response) => {          //这里使用了ES6的语法
                    //(response)       //请求成功返回的数据
                    //console.log(response)
                    if (response.status == 200 && response.data.state == "ok") {
                        this.tableDataModel = response.data.page.list
                        this.tableDataModelPage.pageSize = response.data.page.pageSize
                        this.tableDataModelPage.total = response.data.page.totalRow
                        this.tableDataModelPage.currentPage = response.data.page.pageNumber
                        //console.log(response)
                    } else {
                        return
                    }
                }).catch((error) => {
                    console.log(error)
                });
            },
       },computed: {
       },
        created() {
            this.$nextTick(() => {
                // 手动将表格和工具栏进行关联
                this.$refs.xGrid.connect(this.$refs.xToolbar2)
                if (!this.isBlankVue(this.$route.query.type)){
                    this.type = this.$route.query.type;
/*                    this.formData.warehouse = this.$route.query.warehouse;
                    this.pickerDateMonth = this.$route.query.pickerDateMonth;
                    this.$_pickerDateEvent();*/
                    this.changeTableColumn();
               }else{
                    this.search();
               }
           })

       }

   };


</script>

<style scoped>
    .header-wrapepr {
        display: flex;
        flex-direction: row;
        justcontent: space-between
   }

    .el-date-editor.el-input, .el-date-editor.el-input__inner {
        width: 130px;
   }

    .el-form-item__content .el-input {
        width: 130px;
   }

    /deep/.vxe-table--render-default .vxe-header--column.col--right{
        text-align: left !important;
    }

    /deep/.my-domain.vxe-input {
        height: 34px;
        width: 300px;
    }
    /deep/.my-domain.vxe-input .vxe-input--prefix {
        width: 60px;
        height: 32px;
        top: 1px;
        text-align: center;
        border-right: 1px solid #dcdfe6;
        background-color: #f5f7fa;
    }
    /deep/.my-domain.vxe-input .vxe-input--inner {
        padding-left: 72px !important;
        border: 1px solid #dcdfe6;
    }
    /deep/.my-domain.vxe-input .vxe-input--suffix {
        width: 90px;
        height: 32px;
        top: 1px;
        text-align: center;
        border-left: 1px solid #dcdfe6;
        background-color: #f5f7fa;
        cursor: pointer;
    }
</style>
